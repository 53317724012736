import {Statusbar} from "./GUI/statusbar/Statusbar.ts";
import {Keylogger} from "./Keylogger.ts";
import {MessageService} from "./MessageService.ts";
import {User} from "./User";
import {initializeCanvasManager} from "./canvasManager";
import {BackendAdapter} from "./communication/backendAdapter";
import {initializeCommunicationManagerLegacy} from "./communication/communicationManagerLegacy";
import {initializeContextMenuManager} from "./contextMenuManager";
import {initializeDataManager, finalizeDataManager} from "./dataManager";
import {initializeDialogManager} from "./dialogManager";
import {EventManager} from "./eventManager";
import {initializeGraphics} from "./graphics";
import {initializeGuiManager, createSplashScreen} from "./guiManager";
import {initializeJsPlumbManager} from "./jsPlumb/jspManager";
// import {initializeLoadingScreen}										from "./loadingScreen";
import {initializeLocalizationManager, switchLanguage} from "./localization/localizationManager";
import {initializePanelButtons} from "./panelButtons";
import {initializePartListManager} from "./partListManager";
import {PdfController} from "./pdfExport/pdfController";
import {initializeReferenceDesignatorManager} from "./referenceDesignatorManager";
import {initializeReact} from "./index";

export const GLOBALEVENTMANAGER = new EventManager();
export const STATUSBAR = new Statusbar(document.getElementById("statusPanel"), GLOBALEVENTMANAGER);
export const MESSENGER = new MessageService(STATUSBAR.messenger);
export const KEYLOGGER = new Keylogger();

export const USER = new User(true);
export const BACKEND = new BackendAdapter();
// export const LOCALIZER = new Localizer(GLOBALEVENTMANAGER, true);

export const pdfController = new PdfController();
export const applicationRoot = {
	environment: null,
	debugMode: null,
	shopURL: null,
	buildInfo: {
		/* eslint-disable camelcase */
		mode: __INJECTED_BY_WEBPACK_mode__,
		branchName: __INJECTED_BY_WEBPACK_branchName__,
		branchType: __INJECTED_BY_WEBPACK_branchType__,
		commitHash: __INJECTED_BY_WEBPACK_commitHash__,
		commitDate: __INJECTED_BY_WEBPACK_commitDate__,
		currentTag: __INJECTED_BY_WEBPACK_currentTag__,
		/* eslint-enable camelcase */
	},
};

export const gitInfo = applicationRoot.buildInfo;

/* Enumeration of different environment modes */
export const environmentTypeEnum = {
	PRODUCTION: {type: "PRODUCTION"},
	TESTING: {type: "TESTING"},
	DEVELOPMENT: {type: "DEVELOPMENT"},
};

/** Standard initialization routine */
async function initializeApplicationManager() {
	const tmpEnvironment = await BACKEND.getEnvironment();
	applicationRoot.environment = environmentTypeEnum[tmpEnvironment.environment];
	applicationRoot.shopURL = tmpEnvironment.shopURL;
}

/** First phase of initialization, handling all stuff that is not sensitive to timing */
export async function initialization() {
	// initializeLoadingScreen();
	await USER.update();
	initializeLocalizationManager(USER.language, false);
	initializeApplicationManager();
	await initializeCommunicationManagerLegacy();
	initializeGraphics();
	initializeGuiManager(USER);
	initializeReferenceDesignatorManager();
	initializeJsPlumbManager(true);
	initializeCanvasManager();
	initializeDataManager(true);
	initializePartListManager();
	initializeContextMenuManager();
	initializePanelButtons();
	initializeDialogManager();
	await switchLanguage(USER.language);
	finalizeDataManager();
	initializeReact(); //! breaks setting drawMode

	if (applicationRoot.buildInfo.mode !== "development-live" && applicationRoot.buildInfo.mode !== "development") createSplashScreen(); // suppress splash if in development-live or in develop;

	GLOBALEVENTMANAGER.dispatch("eGUI_ToggleDrawMode", USER.drawMode); //! broken by initializeReact
	MESSENGER.post2statusbar("WARNING", "modalDialog.splashScreen.greeting");
}
