//? GOOD READS:
//? 		https://developer.mozilla.org/en-US/docs/Web/API/Request
//? 		https://developer.mozilla.org/en-US/docs/Web/API/response
//? 		https://developer.mozilla.org/en-US/docs/Web/HTTP/Content_negotiation

//TODO @CL
//TODO	find a way to gracefully degrade on errors and handle 204, this should help:
//TODO			https://dev.to/sarahob/that-s-so-fetch-4fo3
//TODO			https://dmitripavlutin.com/javascript-fetch-async-await/
//TODO			https://javascript.info/async-await#error-handling
//TODO			https://developer.mozilla.org/en-US/docs/Learn/JavaScript/Asynchronous/Async_await#adding_error_handling

//TODO	Implement runtime type validation via zod

//TODO			see https://lq-group.atlassian.net/browse/LQSK-1571?atlOrigin=eyJpIjoiM2ZkNDU2ZWNlYWE2NGY5MWE4YmE1NjU0MTVmODE0NjkiLCJwIjoiaiJ9
//TODO			https://www.npmjs.com/package/zod


// common base path for all routes
const apiBase = `${location.href}appServer/API`; //*Path to the standard API
const laravelApiBase = `${location.href}app_L/public`; //*Path to the new Laravel based API

// List of all available routes used to feed genericRequest wrapper
const requestEnum = {
	/* eslint-disable key-spacing */
	SPLASH:							{method: "GET",			route: `${laravelApiBase}/base/splashFile.php`,				format: "text/plain",					expectContent: true},			// may result in a 204 - No content for ANNOUNCEMENT/CHANGELOG
	ENVIRONMENT:				{method: "GET",			route: `${laravelApiBase}/base/environment.php`,				format: "application/json",		expectContent: true},
	USERINFO_GET:				{method: "GET",			route: `${laravelApiBase}/users/user.php`,							format: "application/json",		expectContent: true},
	USERINFO_PATCH:			{method: "PATCH",		route: `${laravelApiBase}/users/user.php`,							format: "application/json",		expectContent: false},
	TRANSLATION:				{method: "GET",			route: `${laravelApiBase}/base/translationFile.php`,		format: "application/json",		expectContent: true},
	PROJECT_GET:				{method: "GET",			route: `${laravelApiBase}/users/project.php`,					format: "application/json",		expectContent: true},
  PROJECT_PUT:				{method: "PUT",			route: `${laravelApiBase}/users/project.php`,					format: "application/json",		expectContent: true},
	PROJECT_DELETE:			{method: "DELETE",	route: `${laravelApiBase}/users/project.php`,					format: "application/json",		expectContent: true},
	PROJECTSINFO_GET:		{method: "GET",			route: `${laravelApiBase}/users/projectsInfo.php`,			format: "application/json",		expectContent: true},
	/* eslint-enable key-spacing */
};


/**
 * Provides a central object for communication with the backend.
 */
export class BackendAdapter {
	/**
	 * Creates an instance of BackendAdapter.
	 * @param {boolean} [_debugMode=false] turn chatty log on/off
	 */
	constructor(_debugMode = false) {
		// Prevent multiple instances
		if (BackendAdapter.__instance) throw new Error("Singleton classes can't be instantiated more than once.");
		BackendAdapter.__instance = this;

		this.__debugMode = _debugMode;
		this.__contentEncoding = "utf-8";

		if (this.__debugMode) {
			// eslint-disable-next-line no-console
			console.debug("%cDebugMode: BACKEND", "color: green");
			window.BACKEND = this;
		}
	}

	/**
	 * Request GET environment.
	 * @returns {Promise} result of request, resolves to ["PRODUCTION"|"TESTING"|"DEVELOPMENT"]
	 */
	getEnvironment() {
		return this.__genericRequest(requestEnum.ENVIRONMENT, null, null);
	}

	/**
	 * Request GET splash item.
	 * @param  {splashTypeEnum} _subject to fetch
	 * @param  {languageEnum} _language of _subject
	 * @returns {Promise} result of request, resolves to [GREETER-markdown|CHANGELOG-markdown,ANNOUNCEMENT-markdown]
	 */
	getSplashItem(_subject, _language) {
		return this.__genericRequest(requestEnum.SPLASH, {subject: _subject.type, language: _language.type}, null);
	}

	/**
	 * Request GET user settings.
	 * @returns {Promise} result of request, resolves to an object containing user info
	 */
	getUserSettings() {
		return this.__genericRequest(requestEnum.USERINFO_GET, null, null);
	}

	/**
	 * Request PATCH user settings.
	 * @param {number} _userId whose settings are changed
	 * @param {object} _userSettings object with key/value pairs matching the defined api-user-model (basically all mutable user settings)
	 * @returns {Promise} result of request, resolves to an object containing user info
	 */
	updateUserSettings(_userId, _userSettings) {
		return this.__genericRequest(requestEnum.USERINFO_PATCH, {userId: _userId}, _userSettings);
	}

	/**
	 * Request GET translation file.
	 * @param {languageEnum} _language to request
	 * @returns {Promise} result of request, resolves to an object containing translation for a specific language
	 */
	getTranslationResource(_language) {
		return this.__genericRequest(requestEnum.TRANSLATION, {language: _language.type}, null);
	}

	/**
	 * Request GET projects metadata.
	 * @param {number} _userId of the user, whose settings are changed
	 * @returns {Promise} result of request, resolves to an array of project metadata objects
	 */
	getProjectsInfo(_userId) {
		return this.__genericRequest(requestEnum.PROJECTSINFO_GET, {userId: _userId}, null);
	}

	/**
	 * Request GET project.
	 * @param {number} _userId of the user, whose project is loaded
	 * @param {number} _projectId of the project to load
	 * @returns {Promise} result of request, resolves to project and its metadata
	 */
	loadProject(_userId, _projectId) {
		return this.__genericRequest(requestEnum.PROJECT_GET, {userId: _userId, projectId: _projectId}, null);
	}

	/**
	 * Request DELETE project.
	 * @param {number} _userId of the user, whose project is deleted
	 * @param {number} _projectId of the project to delete
	 * @returns {Promise} result of request, resolves to the deleted project's metadata
	 */
	deleteProject(_userId, _projectId) {
		return this.__genericRequest(requestEnum.PROJECT_DELETE, {userId: _userId, projectId: _projectId}, null);
	}

	/**
	 * Request PUT project.
	 * @param {number} _userId of the user, whose project is saved
	 * @param {number|null} _projectId of the project to save, null if you want to create a new resource
	 * @param {string} _projectName to save project under
	 * @param {object} _project project data object
	 * @returns {Promise} result of request, resolves to the put project's metadata
	 */
	saveProject(_userId, _projectId, _projectName, _project) {
		const tmpQueryParameters = (_projectId) ? {userId: _userId, projectId: _projectId} : {userId: _userId};
		return this.__genericRequest(requestEnum.PROJECT_PUT, tmpQueryParameters, {projectName: _projectName, data: _project});
	}

	/**
	 * Generic wrapper for asynchronous requests.
	 * @param {object} _requestData payload of request, provided as a member of requestEnum
	 * @param {string} _requestData.method request http method
	 * @param {string} _requestData.route request endpoint
	 * @param {string} _requestData.format request format
	 * @param {boolean} _requestData.expectContent response should result in content
	 * @param {object|null} _queryParameters of request
	 * @param {object|null} _bodyParameters of request (provided as a raw js object)
	 * @returns {Promise} result of request for further processing
	 */
	async __genericRequest(_requestData, _queryParameters, _bodyParameters) {
		const tmpUrl = new URL(_requestData.route, location);
		if (_queryParameters) tmpUrl.search = new URLSearchParams(_queryParameters);													// constructs the query string

		const tmpHeaders = this.__createHeaders(_requestData, _bodyParameters);

		const request = this.__createRequest(tmpUrl, _requestData, tmpHeaders, _bodyParameters);

		// eslint-disable-next-line no-console
		if (this.__debugMode) console.debug(`REQUEST:  ${request.method} ${request.url}`);

		const response = await fetch(request);
		this.__evaluateResponse(response);
		const responseData = this.__extractResponseData(response);

		//REFACTOR possible approach for evaluation/204 handling/error handling
		// const evaluation = this.__evaluateResponse(response);
		// const responseData = (evaluation) ? this.__extractResponseData(_requestData, response) : null;


		// eslint-disable-next-line no-console
		if (this.__debugMode) console.debug(`RESPONSE: ${request.method} ${response.url} ${response.status}`);

		return responseData;
	}

	/**
	 * Creates a headers object.
	 * Evaluates the request method and parameters to set the appropriate Content-Type (POST/PUT/...) or Accept (GET,...) header properties
	 * @param {object} _requestData payload of request, provided as a member of requestEnum
	 * @param {string} _requestData.format request format
	 * @param {boolean} _requestData.expectContent response should result in content
	 * @param {object|null} _bodyParameters of the request
	 * @returns {Headers} Headers object
	 */
	__createHeaders(_requestData, _bodyParameters) {
		const tmpHeaders = new Headers();
		tmpHeaders.append("charset", this.__contentEncoding);

		if (_requestData.expectContent) tmpHeaders.set("Accept", _requestData.format);												// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Accept
		if (_bodyParameters) tmpHeaders.set("Content-Type", _requestData.format);															// https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Content-Type

		return tmpHeaders;
	}

	/**
	 * Creates a request object.
	 * Adds a body if provided with _bodyParameters.
	 * @param {URL} _url endpoint of request
	 * @param {object} _requestData payload of request, provided as a member of requestEnum
	 * @param {string} _requestData.method request http method
	 * @param {Headers} _headers to add to request
	 * @param {object|null} _bodyParameters to add to request as a body
	 * @returns {Request} Request object
	 */
	__createRequest(_url, _requestData, _headers, _bodyParameters) {
		const tmpRequest = new Request(
			_url,
			{
				method: _requestData.method,
				headers: _headers,
				...(_bodyParameters && {body: JSON.stringify(_bodyParameters, null, "\t")}),		// conditionally add body property if _bodyParameters is truthy
			},
		);
		return tmpRequest;
	}

	//REFACTOR This is a mess! Find something better!
	/**
	 * Evaluates response and handles fetch errors if necessary.
	 * @param {Response} _response to check for errors
	 * @throws Error with additional details (only when necessary)
	 * @returns {boolean} xxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxxx
	 */
	__evaluateResponse(_response) {
		if (!_response.ok) {
			window.alert(`Error ${_response.status}: «${_response.statusText}» for ${_response.url}`);		//TODO replace with MESSENGER.post2window
			// throw new Error(`Error ${_response.status}: «${_response.statusText}» for ${_response.url}`);
		}

		let result = true;

		switch (_response.status) {																																						// https://developer.mozilla.org/de/docs/Web/HTTP/Status
			case 200:		// OK
				break;
			case 204:		// No Content
				result = false;
				break;
			case 400:		// Bad Request
				break;
			case 404:		// Not found
				break;
			case 405:		// Method Not Allowed
				break;
			case 500:		// Internal Server Error
				break;
			default:
				break;
		}

		return result;
	}

	/**
	 * Extracts response payload depending on contentType
	 * @param {Response} _response to extract payload from
	 * @returns {Promise|null} containing payload or null for 204 responses
	 */
	__extractResponseData(_response) {
		if (_response.status == 204) return null;																															// return early if no content was received //REFACTOR get rid of this with evaluateResponse, only handle true responses here!

		const tmpContentType = _response.headers.get("Content-Type");
		switch (true) {
			case tmpContentType.includes("application/json"):
				return _response.json();
			case tmpContentType.includes("text/plain"):
				return _response.text();
			default:
				throw new Error(`Unknown contentType "${tmpContentType}"`);
		}
	}
}
