/* cSpell:disable */
import {GLOBALEVENTMANAGER, MESSENGER, BACKEND, USER} from "./applicationManager";
import {Connection} from "./connections/_connection.LEGACY";
import {interactionPresetsEnum, validationStatusEnum} from "./constantsAndEnumerations";
import {dataRoot, getDeviceSourceDataByDatabaseId, addDataNodeToNodeList, getUniqueDataNodeByType, getDataNodeByUUID, removeDataNodeFromNodeList} from "./dataManager";
import {assemblyNodeTemplate} from "./dataNodes/templates/assemblyNodeTemplate";
import {infrastructureNodeTemplate} from "./dataNodes/templates/infrastructureNodeTemplate";
import {limboNodeTemplate} from "./dataNodes/templates/limboNodeTemplate";
import {projectNodeTemplate} from "./dataNodes/templates/projectNodeTemplate";
import {trashNodeTemplate} from "./dataNodes/templates/trashNodeTemplate";
import {unitNodeTemplate} from "./dataNodes/templates/unitNodeTemplate";
import {EventManager} from "./eventManager";
import {nodeGraphicsFactory} from "./graphics";
import {
	debounce,
	searchArrayForElementByKeyValuePair,
	checkStringValidity,
	checkUUIDValidityNew,
	findSmallestMissingNumber,
	adjustPortOrientation,
	isConsumer,
	isMotor,
} from "./helper";
import {interfaceFactory} from "./interfaces/interfaceFactory.ts";
import {getTranslation, isDefaultTranslationValue} from "./localization/localizationManager";
import {Port} from "./ports/port";
import {portFactory} from "./ports/portFactory.ts";
import {
	ProjectReferenceDesignatorHandler,
	InfrastructureNodeReferenceDesignator,
	InfrastructureReferenceDesignatorHandler,
	TrashNodeReferenceDesignator,
	TrashNodeReferenceDesignatorHandler,
	AssemblyNodeReferenceDesignator,
	AssemblyNodeReferenceDesignatorHandler,
	DeviceNodeReferenceDesignator,
	UnitNodeReferenceDesignator,
} from "./referenceDesignatorManager";

import cloneDeep from "lodash.clonedeep";
import {invertPortGender, PortSide} from "./ports/utils";

//TODO replace portsUpdated by specific handlers for interfaceTypeChanged and interfaceParameterChanged, maybe even interfaceVoltageChanged...

/* ######### Quick and dirty hack to satisfy eslint-jsdoc/no-undefined-types (TS - I wish you were here!) ######### */

//! Don't rely on the type definitions made here!!!

/* eslint-disable jsdoc/require-property */
/**
 * Lets define some custom types.
 * @typedef {object} DataNode
 */
/* eslint-enable jsdoc/require-property */

/* ################################################################################################################ */

/** Base class for all dataNodes incl. cables*/
class SuperBaseNode {
	/**
	 * Creates a new instance of SuperBaseNode.
	 * @param {string} _name of this dataNode
	 * @param {string} _UUID of this dataNode
	 * @param {object} _baseData node details, provided either via restoreData when loading from saveFile or via baseTemplate / sourceData when creating a new node
	 */
	constructor(_name, _UUID, _baseData) {
		this.name = _name;
		this.nameSuffix = null;
		this.i18nIdentifier = _baseData.i18nKey;
		this.UUID = checkUUIDValidityNew(_UUID);
		checkStringValidity(_name);
		this.graphics = nodeGraphicsFactory(_baseData.graphics); // you need to create a new graphics object via the factory; otherwise the drawModeEvent is not registering?!
	}
}

/** Base class for all dataNodes excl. cables*/
export class BaseDataNode extends SuperBaseNode {
	/**
	 * Creates a new instance of BaseDataNode.
	 * @param {string} _name of this dataNode
	 * @param {string} _UUID of this dataNode
	 * @param {object} _baseData node details, provided either via restoreData when loading from saveFile or via baseTemplate / sourceData when creating a new node
	 */
	constructor(_name, _UUID, _baseData) {
		super(_name, _UUID, _baseData);
		// this.name = _name;
		// this.nameSuffix = null;
		// this.i18nIdentifier = _baseData.i18nKey;
		// this.UUID = checkUUIDValidityNew(_UUID);
		// checkStringValidity(_name);

		this.children = [];

		this.parentUUID = "root"; // new Nodes always get created under root (and later moved to their final location via parentNode.addChild or Node.relocate)
		this.parent = null;
		this.setTrashed(false); // flag for marking dataNodes in Trash, new Nodes are never trashed (not even on reloading from saved files)

		this.registerNodeName();

		addDataNodeToNodeList(this);

		this.localEvents = []; // stores all local event(handler)s
		this.registerEventHandlers();
		this.eventManager = new EventManager();
		this.keyParameters = [];
		if (_baseData.keyParameters != undefined) {
			_baseData.keyParameters.forEach((tmpParameter) => {
				this.keyParameters.push(tmpParameter);
			});
		}

		this.__databaseId = _baseData.databaseId; //! deliberately without a getter; leave this hidden/private (__...) for now to help identify all places where we use this -> we should never use databaseId on the client
		this.group = _baseData.group;
		this.subGroup = _baseData.subGroup;
		this.role = _baseData.role;

		this.validationStatus = validationStatusEnum.ERROR; // TODO this is just for testing! We need (a) customTypes to really updated the validation state of a dataNode and (b) all nodes should be initialized as erroneous

		this.nodeType = this.getType(); // needed to identify data while importing from json files
		this.interactionPreset = interactionPresetsEnum[this.nodeType.toUpperCase()];
		this.autoConfig = !!_baseData.autoConfig;

		this.ports = [];
		// this.availablePorts = _baseData.availablePorts;
		this.connections = [];

		this.description = _baseData.description;

		this.materialNumber = _baseData.materialNumber;
		// this.isArchived = null;
		// this.level = null;
		this.manufacturer = _baseData.manufacturer;
		// this.materialNumber = null;
		// this.price = null;

		// parse sourceData (without ports! Ports need an already defined and registered dataNode first, which is done via "eDTM_DataNodeCreated" Event)
		// if (_sourceData != null) {
		// 	this.description = _sourceData.description;
		// 	this.__databaseId = _sourceData.databaseId;
		// 	this.group = _sourceData.group;
		// 	this.subGroup = _sourceData.subGroup;
		// 	this.isArchived = _sourceData.isArchived;
		// 	this.level = _sourceData.level;
		// 	this.manufacturer = _sourceData.manufacturer;
		// 	this.price = _sourceData.price;

		this.decentralControl = _baseData.decentralControl;

		this.signatures = {
			portAdded: "dataNode.port.added",
			portRemoved: "dataNode.port.removed",
			portsUpdated: "dataNode.ports.updated",
		};
	}

	/** Adding eventHandlers to this DataNode */
	registerEventHandlers() {
		this.localEvents.push(
			createHandler("eDTM_ChildDataNodeAdded", (_dataNode) => {
				if (_dataNode.parentUUID == this.UUID && getDataNodeByUUID(_dataNode.parentUUID).nodeType != "LimboNode") {
					this.referenceDesignatorHandler.add(_dataNode);
				}
			}),
		);

		this.localEvents.push(
			createHandler("eDTM_ChildDataNodeRemoved", (_dataNode) => {
				if (_dataNode.parentUUID == this.UUID) this.referenceDesignatorHandler.remove(_dataNode);
			}),
		);

		this.localEvents.push(
			createHandler("eTM_Translate", () => {
				if (this.i18nIdentifier != null) {
					if (isDefaultTranslationValue(this.name)) this.setName(getTranslation(this.i18nIdentifier)); // only translate default names; custom names (= user provided) never get overwritten!
				}
			}),
		);

		/**
		 * Helper function to define local event handlers
		 * @param {string} _name name of event to bind to
		 * @param {Function} _callback callback to run on event
		 * @returns {object} handlerObject
		 */
		function createHandler(_name, _callback) {
			const tmpHandler = {
				name: _name,
				callback: _callback,
			};
			GLOBALEVENTMANAGER.addHandler(tmpHandler.name, tmpHandler.callback);
			return tmpHandler;
		}
	}

	/** Removing eventHandlers of this DataNode (necessary on delete to remove any leftovers) */
	unregisterEventHandlers() {
		this.localEvents.forEach((handler) => {
			GLOBALEVENTMANAGER.removeHandler(handler.name, handler.callback);
		});
	}

	/**
	 * Returns the validation status of this dataNode
	 * @returns {validationStatusEnum} of this dataNode
	 */
	getValidationStatus() {
		return this.validationStatus;
	}

	/**
	 * Sets this dataNodes validation status
	 * @param {validationStatusEnum} _validationStatus to set for this dataNode
	 */
	setValidationStatus(_validationStatus) {
		if (this.validationStatus != _validationStatus) {
			this.validationStatus = _validationStatus;
			GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeValidationChanged", this);
		}
	}

	/**
	 * Returns the trashed property of this dataNode
	 * @returns {string} name of this dataNode
	 */
	getName() {
		if (this.nameSuffix != 0) {
			return `${this.name} (${this.nameSuffix})`;
		} else {
			return this.name;
		}
	}

	/**
	 * Changes the name of dataNode
	 * @param {string} _newName of dataNode
	 */
	setName(_newName) {
		checkStringValidity(_newName);
		const oldName = this.name;
		if (_newName != oldName) {
			this.unRegisterNodeName();
			this.name = _newName;
			this.registerNodeName();
			GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeRenamed", this);

			//JIRA [LQSK-1397] Sprache wechseln sollte kein Log in den Statusbar-Messenger triggern
			MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-renamed", {dataNode: this});
		}
	}

	/**
	 * Returns the description of this dataNode
	 * @returns {string} description
	 */
	getDescription() {
		return this.description;
	}

	/**
	 * Sets the description of this dataNode
	 * @param {string} _newDescription to set
	 */
	setDescription(_newDescription) {
		if (_newDescription != this.description) {
			this.description = _newDescription;
			// GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeDescriptionChanged", this);
		}
	}

	/**
	 * Returns the tooltip of this dataNode
	 * @returns {string} graphics.tooltip
	 */
	getTooltip() {
		return this.graphics.tooltip;
	}

	/**
	 * Sets the tooltip of this dataNode (canvas & outliner)
	 * @param {string} _newTooltip to set
	 */
	setTooltip(_newTooltip) {
		checkStringValidity(_newTooltip);
		if (_newTooltip != this.getTooltip()) {
			this.graphics.tooltip = _newTooltip; //! We probably should not wrap the tooltip like this. Decide wether to use dataNode.tooltip or graphics.tooltip!
			GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeTooltipChanged", this);
		}
	}

	/**
	 * Returns the trashed property of this dataNode
	 * @returns {boolean} trashed
	 */
	getTrashed() {
		return this.trashed;
	}

	/**
	 * Sets the trashed property of this dataNode
	 * @param {boolean} _trashed to set
	 */
	setTrashed(_trashed) {
		this.trashed = _trashed;
	}

	/**
	 * Returns the type/name of this class
	 * @throws  {Error} indicating that an abstract method was called
	 */
	getType() {
		throw new Error("This is an abstract base class method!");
	}

	/**
	 * Returns the 1st or 2nd level parentGroupNode of this dataNode; used to identify the associated canvas -> project-/infrastructure-/trash-& assemblyNodes are handled specially
	 */
	getParentGroupNode() {
		throw new Error("This method is abstract and must be implemented in all derived classes");
	}

	/**
	 * Relocates this dataNode to a new parentNode
	 * @param {DataNode} _targetParentNode new container to move dataNode to
	 */
	relocate(_targetParentNode) {
		// Prevent (programmatic) movement to same parentNode (Drag&Drop is handled by outliner)
		if (_targetParentNode.UUID == this.parentUUID) {
			throw new Error(`${this.nodeType}: "${this.UUID}" is already a child of ${_targetParentNode.nodeType}: "${_targetParentNode.UUID}"!`);
		}

		// Remove this dataNode from old parentNode (excluding root parent)
		if (this.parentUUID != "root") getDataNodeByUUID(this.parentUUID).removeChild(this);

		// Add this dataNode to new parentNode
		_targetParentNode.addChild(this);

		// Raise events
		if (_targetParentNode.nodeType != "LimboNode") {
			GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeRelocated", _targetParentNode.UUID, this.UUID);
		}

		if (_targetParentNode.nodeType != "LimboNode" && _targetParentNode.nodeType != "TrashNode") {
			MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-relocated", {dataNode: this, groupNode: _targetParentNode});
		}
	}

	/** Moves this dataNodes children up the hierarchy the next group node and deletes this dataNode (relevant only for UnitNodes ) */
	ungroup() {
		throw new Error("This method is abstract and must be implemented in all derived classes");
	}

	/**
	 * clear (delete) this dataNodes children
	 * @param {boolean} _removePermanently wether to remove children completely or move to trash
	 */
	clear(_removePermanently) {
		this.children.slice().forEach((child) => {
			// delete children first
			child.delete(_removePermanently);
		});
	}

	/**
	 * Deletes this dataNode (and it's children depending on condition)
	 * @param {boolean} _removePermanently wether to remove completely or move to trash
	 */
	delete(_removePermanently) {
		if (_removePermanently ? this.relocate(getUniqueDataNodeByType("LimboNode")) : this.relocate(getUniqueDataNodeByType("TrashNode")));
	}

	/**
	 * Creates a (new) DataNode under this dataNodes children property (basically just a wrapper of _dataNode.relocate)
	 * @param {DataNode} _dataNode to be added
	 */
	createChild(_dataNode) {
		_dataNode.relocate(this);
	}

	/**
	 * Adds a dataNode to this dataNodes children property (part of the relocate process)
	 * @param {DataNode} _dataNode to be removed
	 */
	addChild(_dataNode) {
		this.children.push(_dataNode);
		_dataNode.parentUUID = this.UUID;
		_dataNode.parent = this;
		_dataNode.parentEventManager = this.eventManager;
		if (_dataNode.getType() !== "InfrastructureNode") GLOBALEVENTMANAGER.dispatch("eDTM_ChildDataNodeAdded", _dataNode);
	}

	/**
	 * Removes a dataNode from this dataNodes children property.
	 * @param {DataNode} _dataNode to be removed
	 */
	removeChild(_dataNode) {
		this.children.splice(this.children.indexOf(searchArrayForElementByKeyValuePair(this.children, "UUID", _dataNode.UUID)), 1);
		GLOBALEVENTMANAGER.dispatch("eDTM_ChildDataNodeRemoved", _dataNode);
	}

	/** Sets a dataNode active (pE activating it programmatically in outliner) */
	setActive() {
		if (dataRoot.activeNode !== this) {
			dataRoot.activeNode = this;
			GLOBALEVENTMANAGER.dispatch("eDTM_ActivateDataNodeChanged", this);
		}
		if (dataRoot.activeGroupNode !== this.getParentGroupNode()) {
			dataRoot.activeGroupNode = this.getParentGroupNode();
			GLOBALEVENTMANAGER.dispatch("eDTM_ActivateGroupNodeChanged", this.getParentGroupNode());
		}
	}

	/**
	 * Adds a port to this DataNode.
	 * New function to consolidate Port handling from Connections and DataNodes
	 * @param {Port} _port to add
	 * @fires dataNode.port.added
	 * @fires eDTM_DataNodePortAdded legacy reasons, might get removed in the future
	 */
	addPort(_port) {
		_port.parentUUID = this.UUID;
		_port.parent = this;
		this.ports.push(_port);
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodePortAdded", _port);
		this.eventManager.dispatch(this.signatures.portAdded, _port);

		//REFACTOR replace portsUpdated by specific handlers for interfaceTypeChanged and interfaceParameterChanged, maybe even interfaceVoltageChanged...
		_port.eventManager.addHandler(_port.signatures.interfaceParameterChanged, () => {
			this.eventManager.dispatch(this.signatures.portsUpdated, this);
		});
	}

	/**
	 * Removes a port from this dataNode
	 * @param {Port} _port of port to remove
	 * @fires dataNode.port.removed
	 * @fires eDTM_DataNodePortRemoved legacy, will get removed in the future
	 */
	removePort(_port) {
		// remove connection first
		if (_port.isConnectedTo) this.getParentGroupNode().removeConnection(_port.isConnectedTo.parent);

		this.ports = this.ports.filter((port) => port.UUID !== _port.UUID);

		_port.eventManager.removeHandler(_port.signatures.interfaceParameterChanged, () => {
			this.eventManager.dispatch(this.signatures.portsUpdated, this);
		});

		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodePortRemoved", _port, getDataNodeByUUID(this.UUID));
		this.eventManager.dispatch(this.signatures.portRemoved, _port);
	}

	/** Creates a connection between two child-dataNodes of a container */
	addConnection() {
		throw new Error("This method is abstract and must be implemented in all derived classes");
	}

	/**
	 * Removes a connection of a child-dataNode of a container
	 */
	removeConnection() {
		throw new Error("Abstract base class method. Implement in derived DataGroupNodes.");
	}

	/** Clears all connections of all child-dataNodes of a container */
	clearAllConnections() {
		this.connections.forEach((tmpConnection) => {
			this.removeConnection(tmpConnection);
		});
	}

	/**
	 *	Retrieves a port from local ports[] by its unique ID
	 * @param {string} _uniquePortId of Port to get
	 * @returns {Port} with matching Id
	 */
	getPortByUUID(_uniquePortId) {
		return searchArrayForElementByKeyValuePair(this.ports, "UUID", _uniquePortId);
	}

	/**
	 *	Retrieves a port from local ports[] by its database number
	 * @param {string} _dbNumber of Port to get
	 * @returns {Port} with matching Id
	 */
	getPortByDbNumber(_dbNumber) {
		return searchArrayForElementByKeyValuePair(this.ports, "dbNumber", _dbNumber);
	}

	/**
	 *	Retrieves a connection from local connections[] by its unique ID
	 * @param {string} _UUID of connection to get
	 * @returns {Connection} with matching Id
	 */
	getConnectionByUniqueConnectionId(_UUID) {
		return searchArrayForElementByKeyValuePair(this.connections, "UUID", _UUID);
	}

	/**
	 * Changes this dataNodes position (on canvas)
	 * @param {Array} _position new canvas coordinates
	 */
	setPosition(_position) {
		if (this.graphics.position == _position) return;
		this.graphics.position = _position;
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeRepositioned", this);
	}

	/**
	 *	Returns this datNodes position (on canvas)
	 * @returns {object} position ({x, y})
	 */
	getPosition() {
		return this.graphics.position;
	}

	/**
	 * Searches for product added through auto config
	 * @returns {boolean} - true if an auto config product found
	 */
	isAutoConfigured() {
		let res = false;
		this.children.forEach((e) => {
			if (e.group !== "consumers") {
				// e.autoConfig	// Which condition is right today?
				res = true;
			}
		});
		return res;
	}

	/**
	 * Retrieves this dataNodes data for autoConfig request
	 * @param {boolean} _triggerChildren - process children
	 * @returns {object} autoConfig dataNode representation that gets constructed
	 */
	getAutoConfigData(_triggerChildren = true) {
		const tmpObject = {
			uuid: this.UUID,
			databaseId: null,
			index: this.UUID === "infr" ? -1 : getDataNodeByUUID(this.parentUUID).children.indexOf(this), // temp infr
			decentralControl: this.decentralControl,
			type: {
				isConsumer: isConsumer(this),
				isMotor: isMotor(this),
			},
		};

		setConfigLevel(tmpObject);
		getPorts(tmpObject);
		getDataBaseId(tmpObject);
		if (_triggerChildren) triggerChildrenAutoConfig(tmpObject); //! do not process children for assembly node in the infrastructure view
		return tmpObject;

		/**
		 * Retrieves port information for this dataNode
		 * @param {object} _tmpObject autoConfig dataNode representation that gets constructed
		 */
		function getPorts(_tmpObject) {
			_tmpObject.connectorList = []; // required in REST-definition so moved before the loop
			if (getDataNodeByUUID(_tmpObject.uuid).ports.length !== 0) {
				getDataNodeByUUID(_tmpObject.uuid).ports.forEach((e) => {
					_tmpObject.connectorList.push(e.getAutoConfigData());
				});
			}
		}

		/**
		 * Triggers this dataNodes children to process their local getAutoConfigData()
		 * @param {object} _tmpObject autoConfig dataNode representation that gets constructed
		 */
		function triggerChildrenAutoConfig(_tmpObject) {
			if (tmpObject.uuid == "infr") {
				_tmpObject.deviceList = [];
				getDataNodeByUUID("proj")
					.children.filter((child) => child.getType() !== "InfrastructureNode")
					.forEach((child) => {
						// configure the infrastructure
						_tmpObject.deviceList.push(child.getAutoConfigData(false));
					});
			} else {
				if (getDataNodeByUUID(_tmpObject.uuid).children != null) {
					// do not add assembly's devices when configuring an infrastructure
					_tmpObject.deviceList = [];
					getDataNodeByUUID(_tmpObject.uuid).children.forEach((child) => {
						_tmpObject.deviceList.push(child.getAutoConfigData());
					});
				}
			}
		}

		/**
		 * Retrieves the databaseId of this dataNode (if available)
		 * @param {object} _tmpObject autoConfig dataNode representation that gets constructed
		 */
		function getDataBaseId(_tmpObject) {
			if (getDataNodeByUUID(_tmpObject.uuid).__databaseId != null) {
				_tmpObject.__databaseId = getDataNodeByUUID(_tmpObject.uuid).__databaseId;
			}
		}

		/**
		 * Sets a config level for assembly nodes
		 * @param {object} _tmpObject autoConfig dataNode representation that gets constructed
		 */
		function setConfigLevel(_tmpObject) {
			if (getDataNodeByUUID(_tmpObject.uuid).nodeType == "AssemblyNode") {
				_tmpObject.configLevel = 1;
			}
		}
	}

	/**
	 * Update port positions after adding/removing ports.
	 * @warning This is function debounced!
	 */
	recalculatePortPositions = debounce(() => {
		const energyPorts = this.ports.filter((_port) => _port.interfaces.every((_interface) => _interface.groupX !== "DATA"));
		const energySourcePorts = energyPorts.filter((_port) => _port.side === PortSide.SOURCE);
		const energyTargetPorts = energyPorts.filter((_port) => _port.side === PortSide.TARGET);
		const busPorts = this.ports.filter((_port) => _port.interfaces.some((_interface) => _interface.groupX === "DATA"));
		const busSourcePorts = busPorts.filter((_port) => _port.side === PortSide.SOURCE);
		const busTargetPorts = busPorts.filter((_port) => _port.side === PortSide.TARGET);

		// small helper to prevent recalculating port spacing over and over again
		let factor = 1 / (energyTargetPorts.length + 1);

		for (let i = 0; i < energyTargetPorts.length; i++) {
			const currentIndex = i + 1;
			energyTargetPorts[i].dbNumber = currentIndex;
			energyTargetPorts[i].referenceDesignator = currentIndex;
			energyTargetPorts[i].graphics.symbol.orientation = "NORTH";
			energyTargetPorts[i].graphics.image.orientation = "NORTH";
			energyTargetPorts[i].graphics.symbol.position.x = (i + 1) * factor;
			energyTargetPorts[i].graphics.image.position.x = (i + 1) * factor;
			energyTargetPorts[i].graphics.symbol.position.y = 0;
			energyTargetPorts[i].graphics.image.position.y = 0;
			GLOBALEVENTMANAGER.dispatch("eDTM_UpdateJspPortPosition", this, energyTargetPorts[i]);
		}

		factor = 1 / (energySourcePorts.length + 1);

		for (let i = 0; i < energySourcePorts.length; i++) {
			const currentIndex = energyTargetPorts.length + i + 1;
			energySourcePorts[i].dbNumber = currentIndex;
			energySourcePorts[i].referenceDesignator = currentIndex;
			energySourcePorts[i].graphics.symbol.orientation = "SOUTH";
			energySourcePorts[i].graphics.image.orientation = "SOUTH";
			energySourcePorts[i].graphics.symbol.position.x = (i + 1) * factor;
			energySourcePorts[i].graphics.image.position.x = (i + 1) * factor;
			energySourcePorts[i].graphics.symbol.position.y = 1;
			energySourcePorts[i].graphics.image.position.y = 1;
			GLOBALEVENTMANAGER.dispatch("eDTM_UpdateJspPortPosition", this, energySourcePorts[i]);
		}

		factor = 1 / (busTargetPorts.length + 1);

		for (let i = 0; i < busTargetPorts.length; i++) {
			const currentIndex = energyTargetPorts.length + energySourcePorts.length + i + 1;
			busTargetPorts[i].dbNumber = currentIndex;
			busTargetPorts[i].referenceDesignator = currentIndex;
			busTargetPorts[i].graphics.symbol.orientation = "EAST";
			busTargetPorts[i].graphics.image.orientation = "EAST";
			busTargetPorts[i].graphics.symbol.position.x = 1;
			busTargetPorts[i].graphics.image.position.x = 1;
			busTargetPorts[i].graphics.symbol.position.y = (i + 1) * factor;
			busTargetPorts[i].graphics.image.position.y = (i + 1) * factor;
			GLOBALEVENTMANAGER.dispatch("eDTM_UpdateJspPortPosition", this, busTargetPorts[i]);
		}

		factor = 1 / (busSourcePorts.length + 1);

		for (let i = 0; i < busSourcePorts.length; i++) {
			const currentIndex = energyTargetPorts.length + energySourcePorts.length + busTargetPorts.length + i + 1;
			busSourcePorts[i].dbNumber = currentIndex;
			busSourcePorts[i].referenceDesignator = currentIndex;
			busSourcePorts[i].graphics.symbol.orientation = "WEST";
			busSourcePorts[i].graphics.image.orientation = "WEST";
			busSourcePorts[i].graphics.symbol.position.x = 0;
			busSourcePorts[i].graphics.image.position.x = 0;
			busSourcePorts[i].graphics.symbol.position.y = (i + 1) * factor;
			busSourcePorts[i].graphics.image.position.y = (i + 1) * factor;
			GLOBALEVENTMANAGER.dispatch("eDTM_UpdateJspPortPosition", this, busSourcePorts[i]);
		}
		this.ports.sort((_a, _b) => _a.dbNumber - _b.dbNumber);
	});

	/** TODO */
	registerNodeName() {
		if (!dataRoot.nodeCounter[this.name]) {
			// check if a counter for the provided dataNodeName has already been registered, if not...
			dataRoot.nodeCounter[this.name] = []; // create a new counter
		}

		const tmpSuffix = findSmallestMissingNumber(dataRoot.nodeCounter[this.name]); // find the first available number/index for the provided name
		dataRoot.nodeCounter[this.name].splice(tmpSuffix, 0, tmpSuffix); // update nodeCounter
		this.nameSuffix = tmpSuffix; // write tmpNumber back to DataNode nameSuffix
	}

	/** TODO */
	unRegisterNodeName() {
		dataRoot.nodeCounter[this.name].splice(dataRoot.nodeCounter[this.name].indexOf(this.nameSuffix), 1); // this.nameSuffix remove (splice) dataNodes name number/index nodeCounter
		if (dataRoot.nodeCounter[this.name].length === 0) delete dataRoot.nodeCounter[this.name];
		this.nameSuffix = null; // reset nameSuffix
	}

	/**
	 * Returns the variable characteristics of a BaseDataNode.
	 * @returns {object} containing UUID, type, description, position name and portSaveData
	 */
	save() {
		return {
			UUID: this.UUID,
			databaseId: this.__databaseId,
			description: this.description,
			graphics: this.graphics.save(),
			name: this.name,
			type: this.getType(),
		};
	}
}

/** Unique 1st level hierarchy infrastructure group node */
export class InfrastructureNode extends BaseDataNode {
	/**
	 * Creates a new instance of InfrastructureNode.
	 * @throws exception providing additional information on error
	 */
	constructor() {
		if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "InfrastructureNode")) {
			// verify uniqueness of infrastructureNode
			throw new Error("An InfrastructureNode already exists.");
		}
		super(getTranslation(infrastructureNodeTemplate.i18nKey), "infr", cloneDeep(infrastructureNodeTemplate));
		this.referenceDesignator = new InfrastructureNodeReferenceDesignator(this.UUID);
		this.referenceDesignatorHandler = new InfrastructureReferenceDesignatorHandler(this.UUID);
		this.parentUUID = "proj";
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);

		// HACK: Since infrastructureNode is NOT a child of projectNode, it does not get administered by projectNode.referenceDesignatorHandler (like assemblyNodes)
		//		 therefore it gets added to projectNode.referenceDesignatorHandler manually and the default number is set to "99" (rather clumsily)
		getUniqueDataNodeByType("ProjectNode").referenceDesignatorHandler.add(this);
		// let tmpReferenceDesignatorString = this.referenceDesignator.getReferenceDesignator().string().replace(this.referenceDesignator.getNumberingDependendComponent().number, 99);
		// this.referenceDesignator.overrideAutomaticReferenceDesignator(tmpReferenceDesignatorString);
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "InfrastructureNode";
	}

	/**
	 * InfrastructureNode is it's own ParentGroupNode
	 * @returns {InfrastructureNode} this nodes ParentGroupNode
	 */
	getParentGroupNode() {
		return this;
	}

	/** Overrides base-class functionality*/
	relocate() {
		throw new Error("InfrastructureNode can't be relocated.");
	}

	/** Overrides base-class functionality*/
	ungroup() {
		throw new Error("InfrastructureNode can't be ungrouped.");
	}

	/**
	 * Removes a dataNode from this dataNodes children property and deletes the child's connection(s) first
	 * @param {DataNode} _dataNode to be removed
	 */
	removeChild(_dataNode) {
		const connectionsToRemove = _dataNode.ports.filter((_port) => _port.isConnectedTo).map((_port) => _port.isConnectedTo.parent);
		connectionsToRemove.forEach((_connection) => {
			this.removeConnection(_connection);
		});
		super.removeChild(_dataNode);
	}

	/**
	 * Adds a connection to this DataGroupNode.
	 * @param {Connection} _connection connection to add
	 * @param {Port} _sourceDevicePort of device to connect to
	 * @param {Port} _targetDevicePort of device to connect to
	 */
	addConnection(_connection, _sourceDevicePort, _targetDevicePort) {
		_connection.parentUUID = this.UUID; //REFACTOR somebody is still relying on this
		_connection.parent = this;

		// identify source/targetSides of connection
		// Determination through invertPortGender does not work atm if both ports are open. In this case we use the saved cable port side...
		// Idea: Ports on cables from sourceData don't have a side (yet), ports on cables form saveData have a side though. So we can use this to determine the side of the cable
		const connectionTargetPort = _connection.ports.find((_port) =>
			_port.side ? _port.side === PortSide.TARGET : _port.gender === invertPortGender(_sourceDevicePort.gender) && _port.family === _sourceDevicePort.family,
		);
		const connectionSourcePort = _connection.ports.find((_port) =>
			_port.side
				? _port.side === PortSide.SOURCE
				: _port.gender === invertPortGender(_targetDevicePort.gender) && _port.family === _targetDevicePort.family && _port !== connectionTargetPort,
		);

		//! Don't change connecting order! FIRST: connectedCable, THEN sourceDevicePort & connection.target, LAST targetDevicePort & connection.sourcePort
		//! projectInterfaces relies on the correct order!!!

		_sourceDevicePort.connectedCable = _connection;
		_sourceDevicePort.isConnectedTo = connectionTargetPort;
		connectionTargetPort.isConnectedTo = _sourceDevicePort;

		_targetDevicePort.connectedCable = _connection;
		_targetDevicePort.isConnectedTo = connectionSourcePort;
		connectionSourcePort.isConnectedTo = _targetDevicePort;

		this.connections.push(_connection);

		GLOBALEVENTMANAGER.dispatch("eDTM_CreateConnection", _connection, _sourceDevicePort, _targetDevicePort);
		MESSENGER.post2statusbar("NORMAL", "cables.created", {cable: _connection, dataNode1: _sourceDevicePort.parent, dataNode2: _targetDevicePort.parent});

		// add PortLinks on original ports (in the future this might be also a sourcePort, hence an event for both is fired)
		if (_sourceDevicePort.shadows !== null) GLOBALEVENTMANAGER.dispatch("eDTM_ToggleJspPortLink", getPortByUUID(_sourceDevicePort.shadows), _connection);
		if (_targetDevicePort.shadows !== null) GLOBALEVENTMANAGER.dispatch("eDTM_ToggleJspPortLink", getPortByUUID(_targetDevicePort.shadows), _connection);
	}

	/**
	 * Removes a connection from this DataGroupNode.
	 * @param {Connection} _connection connection to remove
	 */
	removeConnection(_connection) {
		//! Don't change unconnecting order! FIRST: connectedCable, THEN targetDevicePort & connection.sourcePort, LAST: sourceDevicePort & connection.target
		//! projectInterfaces relies on the correct order!!!

		const targetDevicePort = _connection.sourcePort.isConnectedTo;
		const sourceDevicePort = _connection.targetPort.isConnectedTo;

		// remove PortLinks on original ports (in the future this might be also a sourcePort, hence an event for both is fired)
		if (sourceDevicePort.shadows !== null) GLOBALEVENTMANAGER.dispatch("eDTM_ToggleJspPortLink", getPortByUUID(sourceDevicePort.shadows), null);
		if (targetDevicePort.shadows !== null) GLOBALEVENTMANAGER.dispatch("eDTM_ToggleJspPortLink", getPortByUUID(targetDevicePort.shadows), null);

		targetDevicePort.connectedCable = null;
		targetDevicePort.isConnectedTo = null;
		sourceDevicePort.connectedCable = null;
		sourceDevicePort.isConnectedTo = null;

		//* Is that really enough to delete a connection? How fast does the garbarge collector clean that up and frees the UUID?
		this.connections = this.connections.filter((connection) => connection.UUID !== _connection.UUID);

		GLOBALEVENTMANAGER.dispatch("eDTM_RemoveConnection", _connection);
		MESSENGER.post2statusbar("NORMAL", "cables.removed", {cable: _connection, dataNode1: sourceDevicePort.parent, dataNode2: targetDevicePort.parent});
	}

	// @SBI nicht aussagekräftig
	/** Removes every product */
	removeConfiguration() {
		GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", this.UUID, true);

		for (let i = this.children.length - 1; i >= 0; i--) {
			this.children[i].delete(true);
		}

		GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", this.UUID, false);
	}

	/**
	 * clear (delete) this dataNodes children
	 * @param {boolean} _removePermanently wether to remove children completely or move to trash
	 */
	clear(_removePermanently) {
		this.connections = [];
		this.children.slice().forEach((child) => {
			child.delete(_removePermanently);
		});
	}

	/** Overrides base-class functionality */
	delete() {
		throw new Error("InfrastructureNode can't be deleted.");
	}

	/**
	 * Updates infrastructure specific details (used for restoring data)
	 * @param {object} _infrastructureData to restore
	 */
	update(_infrastructureData) {
		this.setDescription(_infrastructureData.description);
	}

	/**
	 * Defines port positions after changing port quantity
	 * @memberof InfrastructureNode
	 */
	recalculatePortPositions() {
		// InfrastructureNode has no ports
	}

	/**
	 * Returns the variable characteristics of the InfrastructureNode.
	 * @returns {object} containing saveData of a BaseDataNode plus childrenSaveData,connectionSaveData and referenceDesignatorSaveData.
	 */
	save() {
		return {
			...super.save(),
			children: this.children.map((child) => child.save()),
			connections: this.connections.map((connection) => connection.save()),
			referenceDesignator: this.referenceDesignator.save(),
			// we could need a kind of unit node to represent an assembly, in case we want to save infrastructure independently
		};
	}
}

/** Unique 1st level hierarchy garbage collector group node */
export class TrashNode extends BaseDataNode {
	/**
	 * Creates a new instance of TrashNode.
	 * @throws exception providing additional information on error
	 */
	constructor() {
		if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "TrashNode")) {
			// verify uniqueness of TrashNode
		}

		super(getTranslation(trashNodeTemplate.i18nKey), "trsh", cloneDeep(trashNodeTemplate));
		this.referenceDesignator = new TrashNodeReferenceDesignator(this.UUID);
		this.referenceDesignatorHandler = new TrashNodeReferenceDesignatorHandler(this.UUID);
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "TrashNode";
	}

	/**
	 * TrashNode is it's own ParentGroupNode
	 * @returns {TrashNode} this nodes ParentGroupNode
	 */
	getParentGroupNode() {
		return this;
	}

	/** Overrides base-class functionality */
	relocate() {
		throw new Error("TrashNode can't be relocated.");
	}

	/** Overrides base-class functionality */
	ungroup() {
		throw new Error("TrashNode can't be ungrouped.");
	}

	/** Overrides base-class functionality */
	clear() {
		this.children.slice().forEach((e) => {
			// delete children first
			e.delete(true);
		});
	}

	/** Overrides base-class functionality */
	delete() {
		throw new Error("TrashNode can't be deleted.");
	}

	/**
	 * Adds a dataNode to this dataNodes children property (part of the deletion process)
	 * @param {DataNode} _dataNode to be trashed
	 */
	addChild(_dataNode) {
		_dataNode.setTrashed(true);
		this.children.push(_dataNode);
		_dataNode.parentUUID = this.UUID;
		_dataNode.parent = this;
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeSendToTrash", _dataNode);
	}

	/**
	 * Rrmoves a dataNode from this dataNodes children property (part of the deletion process)
	 * @param {DataNode} _dataNode to be removed
	 */
	removeChild(_dataNode) {
		_dataNode.setTrashed(false);
		super.removeChild(_dataNode);
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeRemovedFromTrash", _dataNode);
	}

	/** Overrides base-class functionality */
	addConnection() {
		throw new Error("TrashNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	removeConnection() {
		throw new Error("TrashNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	clearAllConnections() {
		throw new Error("TrashNodes don't have connections.");
	}

	/**
	 * Defines port positions after changing port quantity
	 * @memberof TrashNode
	 */
	recalculatePortPositions() {
		// TrashNode has no ports
	}
}

/** Unique 1st level hierarchy permanent delete (pseudo) group node; everything that got relocated to limbo is permanently deleted */
export class LimboNode extends BaseDataNode {
	/** Standard constructor */
	constructor() {
		if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "Limbo")) {
			// verify uniqueness of TrashNode
		}

		super("Limbo", "lmbo", cloneDeep(limboNodeTemplate));
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "LimboNode";
	}

	/**
	 * LimboNode is it's own ParentGroupNode
	 * @returns {LimboNode} this nodes ParentGroupNode
	 */
	getParentGroupNode() {
		return this;
	}

	/** Overrides base-class functionality */
	relocate() {
		throw new Error("LimboNode can't be relocated.");
	}

	/** Overrides base-class functionality */
	ungroup() {
		throw new Error("LimboNode can't be ungrouped.");
	}

	/** Overrides base-class functionality */
	clear() {
		this.children.slice().forEach((e) => {
			// delete children first
			removeDataNodeFromNodeList(e);
		});
		this.children = [];
	}

	/** Overrides base-class functionality */
	delete() {
		throw new Error("LimboNode can't be deleted.");
	}

	/**
	 * Adds a dataNode to this dataNodes children property (part of the deletion process)
	 * @param {DataNode} _dataNode to be removed
	 */
	addChild(_dataNode) {
		this.children.push(_dataNode);
		_dataNode.parentUUID = this.UUID;
		_dataNode.unregisterEventHandlers();
		_dataNode.unRegisterNodeName();
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeSendToLimbo", _dataNode);
		this.clear();
	}

	/** Overrides base-class functionality */
	removeChild() {
		// nothing escapes from Limbo *Muhahaha*
	}

	/** Overrides base-class functionality */
	addConnection() {
		throw new Error("LimboNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	removeConnection() {
		throw new Error("LimboNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	clearAllConnections() {
		throw new Error("LimboNodes don't have connections.");
	}

	/**
	 * Defines port positions after changing port quantity
	 * @memberof LimboNode
	 */
	recalculatePortPositions() {
		// LimboNode has no ports
	}
}

/** Unique 1st level hierarchy project group node */
export class ProjectNode extends BaseDataNode {
	/**
	 * Creates a new instance of ProjectNode.
	 * @param {string} _name of this node
	 * @param {object} _baseData node details, provided either via restoreData when loading from saveFile or via baseTemplate when creating a new project
	 * @throws exception providing additional information on error
	 */
	constructor(_name, _baseData = projectNodeTemplate) {
		const tmpBaseData = cloneDeep(_baseData);
		if (searchArrayForElementByKeyValuePair(dataRoot.nodeList, "getType", "ProjectNode")) {
			// verify uniqueness of project node
			throw new Error("A ProjectNode already exists.");
		}

		super(_name, "proj", tmpBaseData);

		this.referenceDesignatorHandler = new ProjectReferenceDesignatorHandler(this.UUID, tmpBaseData.referenceDesignatorTemplate); // either provided by baseData or restoreData
		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "ProjectNode";
	}

	/**
	 * ProjectNode is it's own ParentGroupNode
	 * @returns {ProjectNode} 1st level parentGroupNode
	 */
	getParentGroupNode() {
		return this;
	}

	/** Overrides base-class functionality */
	relocate() {
		throw new Error("ProjectNode can't be relocated.");
	}

	/** Overrides base-class functionality */
	ungroup() {
		throw new Error("ProjectNode can't be ungrouped.");
	}

	/**
	 * clear (delete) this dataNodes children
	 * @param {boolean} _removePermanently wether to remove children completely or move to trash
	 */
	clear(_removePermanently) {
		this.children
			.filter((child) => child.getType() !== "InfrastructureNode")
			.slice()
			.forEach((child) => {
				child.delete(_removePermanently);
			});
	}

	/** Overrides base-class functionality */
	delete() {
		throw new Error("ProjectNode can't be deleted.");
	}

	/**
	 * Updates project specific details (used for restoring data)
	 * @param {object} _projectData to restore
	 */
	update(_projectData) {
		this.setName(_projectData.name);
		this.setDescription(_projectData.description);
	}

	/** Overrides base-class functionality */
	addConnection() {
		throw new Error("ProjectNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	removeConnection() {
		throw new Error("ProjectNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	clearAllConnections() {
		throw new Error("ProjectNodes don't have connections.");
	}

	/**
	 * Returns the dates of creation and last modification
	 * @returns {object} containing creationDate and modificationDate
	 */
	async getDates() {
		const tmpProjectList = await BACKEND.getProjectsInfo(USER.id);
		const tmpProject = tmpProjectList.filter((tmpProject) => tmpProject.name === this.name)[0];
		const projectDates = {
			creationDate: tmpProject !== undefined ? tmpProject.created : null,
			modificationDate: tmpProject !== undefined ? tmpProject.modified : null,
		};
		return projectDates;
	}

	/**
	 * Defines port positions after changing port quantity
	 * @memberof ProjectNode
	 */
	recalculatePortPositions() {
		// ProjectNode has no ports
	}

	/**
	 * Returns the variable characteristics of the ProjectNode.
	 * @returns {object} containing saveData of a BaseDataNode plus the childrenSaveData.
	 */
	save() {
		return {
			...super.save(),
			children: this.children.map((child) => child.save()),
		};
	}
}

/** 2nd level hierarchy group node */
export class AssemblyNode extends BaseDataNode {
	/**
	 * Creates a new instance of AssemblyNode.
	 * @param {string} _name of this node.
	 * @param {string} _UUID of this node.
	 * @param {object} _baseData node details, either received via BaseDataTemplate (for newly created nodes) or restoreData (for nodes loaded from saveFile).
	 * @throws  exception providing additional information on error.
	 * @fires eDTM_DataNodeCreated
	 */
	constructor(_name, _UUID, _baseData = assemblyNodeTemplate) {
		const tmpBaseData = cloneDeep(_baseData);
		super(_name, _UUID, tmpBaseData);

		this.referenceDesignator = new AssemblyNodeReferenceDesignator(this.UUID);
		this.referenceDesignatorHandler = new AssemblyNodeReferenceDesignatorHandler(this.UUID);
		this.group = "assemblies";

		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
		MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-created", {dataNode: this});
		this.childHandlers = new Map(); // bookkeeping of dynamically added child handlers
		this.portHandlers = new Map(); // bookkeeping of dynamically added ports
	}

	/**
	 * Adds isConnectedToChanged handler to a port initially not present on its device.
	 * Used for cabinets and portType changes atm.
	 * @param {Port} _port to add the handler to.
	 * @listens dataNode.port.added
	 */
	addIsConnectedToHandler(_port) {
		if (_port.side !== PortSide.TARGET) return;

		this.portHandlers.set(_port.UUID, []);
		this.portHandlers.get(_port.UUID).push(
			_port.eventManager.addHandler(_port.signatures.isConnectedToChanged, (port) => {
				this.childPortIsConnectedToChanged(port);
			}),
		);
	}

	/**
	 * Removes isConnectedToChanged handler from a removed port.
	 * Used for cabinets and portType changes atm.
	 * @param {Port} _port to remove the handler from.
	 * @listens dataNode.port.removed
	 */
	removeIsConnectedToHandler(_port) {
		if (_port.side !== PortSide.TARGET) return;
		this.portHandlers.get(_port.UUID).forEach(({event, callback}) => {
			_port.eventManager.removeHandler(event, callback);
		});
	}

	/**
	 * Adds a new Device to Assembly.
	 * @param {DataNode} _dataNode to be added.
	 */
	addChild(_dataNode) {
		super.addChild(_dataNode);

		this.childHandlers.set(_dataNode.UUID, []);

		this.childHandlers.get(_dataNode.UUID).push(
			_dataNode.eventManager.addHandler(_dataNode.signatures.portAdded, (port) => {
				this.addPort(port);
			}),
		);
		this.childHandlers.get(_dataNode.UUID).push(
			_dataNode.eventManager.addHandler(_dataNode.signatures.portRemoved, (port) => {
				this.removePort(port);
			}),
		);
		this.childHandlers.get(_dataNode.UUID).push(
			_dataNode.eventManager.addHandler(_dataNode.signatures.portsUpdated, (dataNode) => {
				this.updatePorts(dataNode);
			}),
		);

		this.childHandlers.get(_dataNode.UUID).push(
			_dataNode.eventManager.addHandler(_dataNode.signatures.portAdded, (port) => {
				this.addIsConnectedToHandler(port);
			}),
		);
		this.childHandlers.get(_dataNode.UUID).push(
			_dataNode.eventManager.addHandler(_dataNode.signatures.portRemoved, (port) => {
				this.removeIsConnectedToHandler(port);
			}),
		);

		_dataNode.ports.forEach((port) => {
			this.addIsConnectedToHandler(port);
			this.addPort(port);
		});
	}

	/**
	 * Removes a dataNode from this dataNodes children property and deletes the child's connection(s) first.
	 * @param {DataNode} _dataNode to be removed.
	 */
	removeChild(_dataNode) {
		const connectionsToRemove = _dataNode.ports.filter((port) => port.isConnectedTo).map((port) => port.isConnectedTo.parent);
		connectionsToRemove.forEach((connection) => {
			this.removeConnection(connection);
		});

		this.childHandlers.get(_dataNode.UUID).forEach(({event, callback}) => _dataNode.eventManager.removeHandler(event, callback));
		this.childHandlers.delete(_dataNode.UUID);

		_dataNode.ports.forEach((port) => {
			this.removeIsConnectedToHandler(port);
			this.removePort(port); // removes a shadowPort
		});

		super.removeChild(_dataNode);
	}

	/**
	 * Adds a shadowPort to this AssemblyNode.
	 * @warning Atm only adds targetPorts.
	 * @param {Port} _originalPort to add an according shadowPort for.
	 * @listens dataNode.port.added
	 */
	addPort(_originalPort) {
		if (_originalPort.side !== PortSide.TARGET) return;

		const portData = _originalPort.save();

		portData.UUID = _originalPort.isShadowedBy ? _originalPort.isShadowedBy : null;
		portData.shadows = _originalPort.UUID;
		const shadowPort = portFactory(portData);

		if (!_originalPort.isShadowedBy) _originalPort.isShadowedBy = shadowPort.UUID;

		//! just a temporary hack. If we had a shadowPort (derived from Port), this could be handled internally by the shadowPort
		adjustPortOrientation(shadowPort);

		super.addPort(shadowPort);

		this.recalculatePortPositions();
	}

	/**
	 * Removes shadowPort according to the given port, in case it exists on this assemblyNode.
	 * @param {Port} _originalPort to remove the according shadowPort for.
	 * @listens dataNode.port.removed
	 */
	removePort(_originalPort) {
		const shadowPort = this.ports.find((port) => port.UUID === _originalPort.isShadowedBy);
		if (!shadowPort) return;

		_originalPort.isShadowedBy = null;

		super.removePort(shadowPort);

		this.recalculatePortPositions();
	}

	/**
	 * Updates shadowPort parameters according to changes on the shadowed counterpart.
	 * @param {DataNode} _dataNode whose ports were updated.
	 * @listens dataNode.ports.update
	 */
	updatePorts(_dataNode) {
		//REFACTOR Make this port specific -> updatePort(_port) like in addPort, removePort
		//REFACTOR The only reason left, not to do this right away are the callbacks in device/motorPropertyDialog and !SameSecondPort
		_dataNode.ports.forEach((originalPort) => {
			const shadowPort = this.ports.find((port) => port.UUID === originalPort.isShadowedBy);
			if (!shadowPort) return;
			const savedInterfaces = originalPort.interfaces.map((_interface) => _interface.save());
			savedInterfaces.forEach((savedInterface) => (savedInterface.UUID = null));
			shadowPort.interfaces.forEach((_interface) => shadowPort.removeInterface(_interface));
			savedInterfaces.forEach((savedInterface) => shadowPort.addInterface(interfaceFactory(savedInterface)));
		});
	}

	/**
	 * Removes the shadowPort, when the according port gets connected.
	 * Adds a shadowPort, when the according port gets disconnected.
	 * @param {Port} _port that's isConnectedTo property has changed.
	 * @listens port.isConnectedTo.changed
	 */
	childPortIsConnectedToChanged(_port) {
		if (_port.isConnectedTo) {
			this.removePort(_port);
		} else {
			this.addPort(_port);
		}
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "AssemblyNode";
	}

	/**
	 * AssemblyNodes are their own ParentGroupNodes
	 * @returns {AssemblyNode} parentGroupNode of this node
	 */
	getParentGroupNode() {
		return this;
	}

	/**
	 * Relocates an AssemblyNode to given targetParentNode.
	 * @param {DataNode} _targetParentNode to move this node to
	 * @memberof AssemblyNode
	 */
	relocate(_targetParentNode) {
		if (_targetParentNode.nodeType != "TrashNode" && _targetParentNode.nodeType != "LimboNode" && _targetParentNode.nodeType != "ProjectNode") {
			throw new Error("AssemblyNodes can only be relocated to ProjectNode, TrashNode or LimboNode.");
		}
		if (this === dataRoot.activeGroupNode && _targetParentNode !== getUniqueDataNodeByType("ProjectNode")) getUniqueDataNodeByType("ProjectNode").setActive();
		super.relocate(_targetParentNode);
	}

	/** Overrides base-class functionality */
	ungroup() {
		// TODO reconsider this naive implementation. What to do with the children?
		this.clear(false);
		super.delete(true);
	}

	/**
	 * Clear (delete) this dataNodes children.
	 * @param {boolean} _removePermanently wether to remove completely or move to trash
	 */
	delete(_removePermanently) {
		if (_removePermanently) {
			// delete children first
			this.children.slice().forEach((e) => {
				e.delete(_removePermanently);
			});
		}
		super.delete(_removePermanently);
	}

	/**
	 * Adds a connection to this DataGroupNode.
	 * @param {Connection} _connection connection to add
	 * @param {Port} _sourceDevicePort of device to connect to
	 * @param {Port} _targetDevicePort of device to connect to
	 */
	addConnection(_connection, _sourceDevicePort, _targetDevicePort) {
		_connection.parentUUID = this.UUID; //REFACTOR somebody is still relying on this
		_connection.parent = this;

		// identify source/targetSides of connection
		// Determination through invertPortGender does not work atm if both ports are open. In this case we use the saved cable port side...
		// Idea: Ports on cables from sourceData don't have a side (yet), ports on cables form saveData have a side though. So we can use this to determine the side of the cable
		const connectionTargetPort = _connection.ports.find((_port) =>
			_port.side ? _port.side === PortSide.TARGET : _port.gender === invertPortGender(_sourceDevicePort.gender) && _port.family === _sourceDevicePort.family,
		);
		const connectionSourcePort = _connection.ports.find((_port) =>
			_port.side
				? _port.side === PortSide.SOURCE
				: _port.gender === invertPortGender(_targetDevicePort.gender) && _port.family === _targetDevicePort.family && _port !== connectionTargetPort,
		);

		//! Don't change connecting order! FIRST: connectedCable, THEN sourceDevicePort & connection.target, LAST targetDevicePort & connection.sourcePort
		//! projectInterfaces relies on the correct order!!!

		_sourceDevicePort.connectedCable = _connection;
		_sourceDevicePort.isConnectedTo = connectionTargetPort;
		connectionTargetPort.isConnectedTo = _sourceDevicePort;

		_targetDevicePort.connectedCable = _connection;
		_targetDevicePort.isConnectedTo = connectionSourcePort;
		connectionSourcePort.isConnectedTo = _targetDevicePort;

		this.connections.push(_connection);

		GLOBALEVENTMANAGER.dispatch("eDTM_CreateConnection", _connection, _sourceDevicePort, _targetDevicePort);
		MESSENGER.post2statusbar("NORMAL", "cables.created", {cable: _connection, dataNode1: _sourceDevicePort.parent, dataNode2: _targetDevicePort.parent});
	}

	/**
	 * Removes a connection from this DataGroupNode.
	 * @param {Connection} _connection connection to remove
	 */
	removeConnection(_connection) {
		//! Don't change unconnecting order! FIRST: connectedCable, THEN targetDevicePort & connection.sourcePort, LAST: sourceDevicePort & connection.target
		//! projectInterfaces relies on the correct order!!!

		const targetDevicePort = _connection.sourcePort.isConnectedTo;
		const sourceDevicePort = _connection.targetPort.isConnectedTo;

		targetDevicePort.connectedCable = null;
		targetDevicePort.isConnectedTo = null;
		sourceDevicePort.connectedCable = null;
		sourceDevicePort.isConnectedTo = null;

		//* Is that really enough to delete a connection? How fast does the garbarge collector clean that up and frees the UUID?
		this.connections = this.connections.filter((connection) => connection.UUID !== _connection.UUID);

		GLOBALEVENTMANAGER.dispatch("eDTM_RemoveConnection", _connection);
		MESSENGER.post2statusbar("NORMAL", "cables.removed", {cable: _connection, dataNode1: sourceDevicePort.parent, dataNode2: targetDevicePort.parent});
	}

	/** Removes everything except for the consumers */
	removeConfiguration() {
		GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", this.UUID, true);

		for (let i = this.children.length - 1; i >= 0; i--) {
			if (this.children[i].group != "consumers") {
				this.children[i].delete(true);
			}
		}

		GLOBALEVENTMANAGER.dispatch("eDTM_SuspendRendering", this.UUID, false);
	}

	/**
	 * Returns the variable characteristics of the AssemblyNode.
	 * @returns {object} containing saveData of a BaseDataNode plus childrenSaveData, connectionSaveData and referenceDesignatorSaveData.
	 */
	save() {
		return {
			...super.save(),
			children: this.children.map((child) => child.save()),
			connections: this.connections.map((connection) => connection.save()),
			ports: [],
			referenceDesignator: this.referenceDesignator.save(),
		};
	}
}

/** 3rd level hierarchy node */
export class UnitNode extends BaseDataNode {
	/**
	 * Creates a new instance of UnitNode.
	 * @param {string} _name of this node
	 * @param {string} _UUID of this node
	 * @param {object} _baseData node details, either received via BaseDataTemplate (for newly created nodes) or restoreData (for nodes loaded from saveFile)
	 * @throws exception providing additional information on error
	 */
	constructor(_name, _UUID, _baseData = unitNodeTemplate) {
		const tmpBaseData = cloneDeep(_baseData);
		super(_name, _UUID, tmpBaseData);

		this.children = null; // unitNodes don't have children (yet)

		this.referenceDesignator = new UnitNodeReferenceDesignator(this.UUID, tmpBaseData.referenceDesignator.deviceComponent.token);

		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
		this.eventManager.addHandler("port.isConnectedTo.changed", (port) => getDataNodeByUUID(this.parentUUID).eventManager.dispatch("port.isConnectedTo.changed", port));
		// Always add ports after eDTM_DataNodeCreated event (ensures the dataNode is finished building when adding port)
		tmpBaseData.ports.forEach((portData) => {
			this.addPort(portFactory(portData));
		});

		MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-created", {dataNode: this});
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "UnitNode";
	}

	/**
	 * Retrieves this nodes parentGroupNode
	 * @returns {AssemblyNode} parentGroupNode of this node
	 */
	getParentGroupNode() {
		return getDataNodeByUUID(this.parentUUID);
	}

	/**
	 * Move this node to a new parentNode
	 * @param {DataNode} _targetParentNode to move this node to
	 */
	relocate(_targetParentNode) {
		if (_targetParentNode.nodeType == "ProjectNode" || _targetParentNode.nodeType == "DeviceNode") {
			throw new Error("UnitNodes can not be relocated to Project- or DeviceNodes.");
		}

		super.relocate(_targetParentNode);
	}

	/**
	 * Adds a port to this UnitNode.
	 * @param {Port} _port to add.
	 * @listens dataNode.port.added
	 */
	addPort(_port) {
		adjustPortOrientation(_port);
		super.addPort(_port);

		this.recalculatePortPositions();
	}

	/** Overrides base-class functionality */
	ungroup() {
		throw new Error("UnitNodes can't be ungrouped.");
	}

	/** Overrides base-class functionality */
	clear() {
		throw new Error("UnitNodes can't be cleared.");
	}

	/** Overrides base-class functionality */
	createChild() {
		throw new Error("UnitNodes don't have children.");
	}

	/** Overrides base-class functionality */
	removeChild() {
		throw new Error("UnitNodes don't have children.");
	}

	/** Overrides base-class functionality */
	addConnection() {
		throw new Error("UnitNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	removeConnection() {
		throw new Error("UnitNodes don't have connections.");
	}

	/** Overrides base-class functionality */
	clearAllConnections() {
		throw new Error("UnitNodes don't have connections.");
	}

	/**
	 * Returns the variable characteristics of a UnitNode.
	 * @returns {object} containing saveData of BaseDataNode plus databaseId and referenceDesignatorSaveData.
	 */
	save() {
		const saveData = {
			...super.save(),
			ports: this.ports.map((port) => port.save()),
			referenceDesignator: this.referenceDesignator.save(),
		};

		// Saving immutable/auto calculated portData doesn't make sense.
		// It messes with SBIs recalculateCurrent (which is ran on project load and calculated currents get added to saved currents).
		// We need to store the UUIDs though to be able to reconstruct connections.
		//! This solution will fail for manually configured source ports (e.g. cabinets). We need to replace SBIs recalculateCurrents with a more robust solution...
		if (this.group === "functions") saveData.ports.forEach((port) => port.interfaces.forEach((_interface) => (_interface.current = 0)));

		return saveData;
	}
}

/** 3rd level hierarchy node*/
export class DeviceNode extends BaseDataNode {
	/**
	 * Creates a new instance of DeviceNode.
	 * @param {string} _name of this node
	 * @param {string} _UUID of this node
	 * @param {object} _baseData node details, either received via sourceData (for newly created nodes) or restoreData (for nodes loaded from saveFile)
	 * @throws  exception providing additional information on error
	 */
	constructor(_name, _UUID, _baseData) {
		const tmpBaseData = cloneDeep(_baseData);
		super(_name, _UUID, tmpBaseData);
		this.sameSecondPort = tmpBaseData.sameSecondPort;
		this.children = null; // deviceNodes don't have children
		this.referenceDesignator = new DeviceNodeReferenceDesignator(this.UUID, tmpBaseData.referenceDesignator.deviceComponent.token);

		GLOBALEVENTMANAGER.dispatch("eDTM_DataNodeCreated", this);
		// this.eventManager.addHandler("port.isConnectedTo.changed", (port) => getDataNodeByUUID(this.parentUUID).eventManager.dispatch("port.isConnectedTo.changed", port));

		// Always add ports after eDTM_DataNodeCreated event (ensures the dataNode is finished building when adding port)
		tmpBaseData.ports.forEach((portData) => {
			this.addPort(portFactory(portData));
		});

		MESSENGER.post2statusbar("NORMAL", "dataManager.dataNode-created", {dataNode: this});
	}

	/**
	 * Returns the type/name of this class
	 * @returns {string} type/name of class
	 */
	getType() {
		return "DeviceNode";
	}

	/**
	 * Gets this nodes parentGroupNode
	 * @returns {AssemblyNode} this nodes parentGroupNode
	 */
	getParentGroupNode() {
		return getDataNodeByUUID(this.parentUUID);
	}

	/**
	 * Moves this node to a new parentNode
	 * @param {DataNode} _targetParentNode to move this node to
	 */
	relocate(_targetParentNode) {
		if (
			_targetParentNode.nodeType != "TrashNode" &&
			_targetParentNode.nodeType != "LimboNode" &&
			_targetParentNode.nodeType != "AssemblyNode" &&
			_targetParentNode.nodeType != "InfrastructureNode"
		) {
			throw new Error("DeviceNodes can only be relocated to Trash-, Limbo-, Assembly- or InfrastructureNodes.");
		}

		super.relocate(_targetParentNode);
	}

	/** Overrides base-class functionality */
	ungroup() {
		throw new Error("DeviceNodes can't be ungrouped.");
	}

	/** Overrides base-class functionality */
	clear() {
		throw new Error("DeviceNodes can't be cleared.");
	}

	/** Overrides base-class functionality */
	createChild() {
		throw new Error("DeviceNodes don't have children.");
	}

	/** Overrides base-class functionality */
	removeChild() {
		throw new Error("DeviceNodes don't have children.");
	}

	/**
	 * Defines port positions after changing port quantity
	 * @memberof DeviceNode
	 */
	recalculatePortPositions() {
		// Do not recalculate device port positions
	}

	/**
	 * Returns the variable characteristics of a DeviceNode.
	 * @returns {object} containing saveData of BaseDataNode plus databaseId and referenceDesignatorSaveData.
	 */
	save() {
		const saveData = {
			...super.save(),
			ports: this.ports.map((port) => port.save()),
			referenceDesignator: this.referenceDesignator.save(),
		};

		// Saving immutable/auto calculated portData doesn't make sense.
		// It messes with SBIs recalculateCurrent (which is ran on project load and calculated currents get added to saved currents).
		// We need to store the UUIDs though to be able to reconstruct connections.		if (this.group === "functions") {
		const sourcePorts = getDeviceSourceDataByDatabaseId(this.__databaseId).ports;
		saveData.ports.forEach((savePort) => {
			const sourcePort = sourcePorts.find((sourcePort) => sourcePort.dbNumber === savePort.dbNumber);
			savePort.interfaces.forEach((saveInterface) => {
				//! breaks if the order of sourcePort.interfaces and savePort.interfaces is not equal
				const sourceInterface = sourcePort.interfaces[savePort.interfaces.indexOf(saveInterface)];

				//! just a temporary solution. Don't rely on databaseId's on the client
				if (sourceInterface.databaseId !== saveInterface.databaseId) throw new Error("Interface type mismatch. Could not reset current");
				saveInterface.current = sourceInterface.current;
			});
		});

		return saveData;
	}
}
