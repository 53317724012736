import {applicationRoot} from "../applicationManager";
import {processSourceData} from "../dataConverter";
import {processConnectorSourceData} from "../dataConverter";
import {processAutoConfigFRH} from "../dataManager";

// WEBPACK local import jQuery
import $ from "jquery";
const jQuery = $;

/** Handles any (bidirectional) client server communication
 *
 * UTILIZING:
 *		jquery ajax, for details see http://api.jquery.com/jQuery.ajax/
 *
 * TODO:
 *		reestablish asynchronous data transfer
 *
 * AUTHOR(S):
 *		Christian Lange
 *
 */

/** Standard initialization routine
 * @deprecated
 * @export
 * @param  {boolean} [_debugMode=false] chatty communication
 */
export async function initializeCommunicationManagerLegacy() {
	//! that doesn't seem to run async???
	const consumers = await requestSourceData2("consumers"); // ToDo: Move to dataManager
	const functions = await requestSourceData2("functions"); // ToDo: Move to dataManager
	const cables = await requestSourceData2("cables"); // ToDo: Move to dataManager
	const connectors = await requestConnectorSourceData(); // ToDo: Move to dataManager

	console.warn("!!! MEASURE-DIGITAL set to 'requiredLeads = 2' instead of 3 !!!");
	console.warn("!!! All IO-Link hacks are marked with @IOLINKHACK !!!");

	processSourceData(consumers);
	processSourceData(functions);
	processSourceData(cables);
	processConnectorSourceData(connectors);
}

// common base path for all routes
const apiBase = `${location.href}appServer/API`; //*Path to the standard API
const laravelApiBase = `${location.href}app_L/public`; //*Path to the new Laravel based API

const contentEncoding = "charset=utf-8";

/** List of all available routes/endpoints, used to feed genericRequest wrapper */
export const requestEnum = {
	CONNECTORDATA: {method: "GET", url: `${apiBase}/base/connectorBaseData.php`, contentType: "application/json"},
	SOURCEDATA: {method: "GET", url: `${apiBase}/base/configurationBaseData.php`, contentType: "application/json"},
	//SOURCEDATA: {method:	"GET",					url: `${laravelApiBase}/base/configurationBaseData.php`,				contentType: "application/json"},
	AUTOCONFIG: {method: "POST", url: `${apiBase}/autoconfig/configurationSystem.php`, contentType: "application/json"},
	//INTERFACEDATA: {method:	"GET",			url: `${apiBase}/base/configurationBaseDataInterface.php`,			contentType: "application/json"},
	CONNECTORS: {method: "POST", url: `${apiBase}/autoconfig/consumerConnectorRequest.php`, contentType: "application/json"},
	ADD2SHOPPINGCART: {method: "POST", url: `${() => applicationRoot.shopURL}/CustomArticles/add`, contentType: "application/xxx"}, //! not sure about the contentType since this is modelled as a form request: https://developer.mozilla.org/en-US/docs/Learn/Forms/Sending_and_retrieving_form_data
};

/** Generic wrapper for specific requests (see requestEnum) -> LEGACY! will be migrated to genericRequest2
 * @deprecated replaced by genericRequest3 (which is async)
 * @param  {requestEnum} _request to send
 * @param  {Object} _arguments to provide
 * @param  {function} _callBackFunction to call on request.complete
 */
export function genericRequestLegacy1(_request, _arguments, _callBackFunction = null) {
	$.ajax({
		type: _request.method,
		url: _request.url,
		data: _arguments,
		dataType: "json",
		async: false,

		complete: (data) => {
			// gets called second but always no matter if an error occurred or not -> use for data handling
			// if provided, handle result to callBackFunction
			if (_callBackFunction == null) return;

			switch (_request.contentType) {
				case "application/json":
					_callBackFunction(data.responseJSON);
					break;
				case "application/text":
					_callBackFunction(data.responseText);
					break;
				default:
					throw new Error(`Unknown contentType "${_request.contentType}"!`);
			}
		},

		success: function (data, textStatus, jqXHR) {},

		error: function (jqXHR, textStatus, errorThrown) {
			if (jqXHR.status == 200) return; // skip "server returned 200" message (which is the notification of success; don't know why this message gets sent via error callback)
			console.error("error", _request, jqXHR, textStatus, errorThrown);
			alert("Communication Error: ", textStatus, errorThrown.message);
			throw new Error("Communication Error: ", textStatus, jqXHR, errorThrown);
		},
	});
}

/** Generic wrapper for specific requests (see requestEnum)
 * @deprecated replaced by genericRequest3 (which is async)
 * @param  {requestEnum} _request to send
 * @param  {Object} _arguments to provide
 * @returns {Promise} success/failure indicator, handler for further processing
 */
function genericRequestLegacy2(_request, _arguments) {
	return new Promise((resolve, reject) => {
		// construct specific request options
		const requestObject = {
			// for details see https://developer.mozilla.org/en-US/docs/Web/API/Request
			method: _request.method,
			headers: {
				"Content-Type": `${_request.contentType};${contentEncoding}`,
			},
			// body: JSON.stringify(content),
		};

		// construct query string from arguments
		const queryString = _arguments ? createQueryString(_arguments) : "";
		fetch(_request.url + queryString, {requestObject})
			.then(evaluateResponse)
			.then((data) => resolve(data))
			.catch((error) => reject(error));
		// .then((data) => console.log("data: ", data))
		// .catch((error) => console.error("error: ", error));
	});

	/** Creates a query string from a data Object (with key value pairs)
	 * @param  {Object} _dataObject to convert to a query string
	 * @returns {String} resulting query string
	 */
	function createQueryString(_dataObject) {
		return (
			"?" +
			Object.entries(_dataObject)
				.map((entry) => {
					return encodeURIComponent(entry[0]) + "=" + encodeURIComponent(entry[1]);
				})
				.join("&")
		);
	}

	/** Helper to identify a appropriate response to content-type and to handle possible response errors
	 * @param  {Object} response to evaluate
	 * @returns {Function} handler for a specific content-type
	 */
	function evaluateResponse(response) {
		const contentType = response.headers.get("content-type");
		if (contentType.includes("application/json")) {
			return handleJSONResponse(response);
		} else if (contentType.includes("text/html")) {
			return handleTextResponse(response);
		} else {
			// add other response types if necessary
			throw new Error(`content-type ${contentType} not supported`);
		}
	}

	/** Specific handler for JSON responses
	 * @param  {Object} response to handle
	 * @returns {Object} response.JSON or promise.reject
	 */
	function handleJSONResponse(response) {
		return response.json().then((json) => {
			if (response.ok) {
				return json;
			} else {
				return Promise.reject(
					Object.assign({}, json, {
						status: response.status,
						statusText: response.statusText,
					}),
				);
			}
		});
	}

	/** Specific handler for XML/text responses
	 * @param  {Object} response to handle
	 * @returns {Object} response.text or promise.reject
	 */
	function handleTextResponse(response) {
		return response.text().then((text) => {
			if (response.ok) {
				return text;
			} else {
				// eslint-disable-next-line prefer-promise-reject-errors
				return Promise.reject({
					status: response.status,
					statusText: response.statusText,
				});
			}
		});
	}
}

/**
 * Request for autoconfig data from server.
 * @param  {object} _configDataInput data to send to backend for autoConfig.
 */
export function requestAutoConfig(_configDataInput) {
	genericRequestLegacy1(
		requestEnum.AUTOCONFIG,
		{action: "", confIn: _configDataInput},
		(_response) => {
			processAutoConfigFRH(_response);
		},
		false,
	);
}

/** Adds articles to the shops shopping cart
 * @param  {Array} _articlesList to add
 */
export function requestAdd2ShoppingCart(_articlesList) {
	const tmpTestArticleList = [
		{
			orderNumber: "1148693-00000",
			quantity: 7,
		},
		{
			orderNumber: "1148670-00000",
			quantity: 3,
		},
	];

	// genericRequest(requestEnum.ADD2SHOPPINGCART, {action: "", confIn: _configDataInput});
	genericRequestLegacy1(requestEnum.ADD2SHOPPINGCART, {configurator__add: tmpTestArticleList});
}

/** Gets svg document content
 * @param  {String} _filePath svg file path
 * @returns  {#document} svg document object
 */
export function getSVGDoc(_filePath) {
	// check performance from FH
	const getSVG = new XMLHttpRequest();
	getSVG.open("GET", _filePath, false);
	getSVG.send();
	return getSVG.responseXML; // .documentElement
}

// ----------------------------------------------------------------

/** Generic wrapper for specific requests (see requestEnum)
 * @param  {requestEnum} _request to send
 * @param  {Object} _arguments to provide
 * @param  {function} _callBackFunction to call on request.complete
 * @returns {Promise} success/failure indicator, handler for further processing
 */
async function genericRequest3(_request, _arguments) {
	const requestObject = {
		// for details see https://developer.mozilla.org/en-US/docs/Web/API/Request
		method: _request.method,
		headers: {
			"Content-Type": `${_request.contentType};${contentEncoding}`,
		},
	};

	const queryString = _createQueryString(_arguments);

	const response = await fetch(_request.url + queryString, {requestObject});

	// error handling
	if (!response.ok) {
		// ideas for graceful degradation?
		const errorMessage = `Error: ${response.status}: ${response.url}`;
		throw new Error(errorMessage);
	}

	const responseData = _extractResponseData(response);
	return responseData;

	/** Creates a query string from a data Object (with key value pairs)
	 * @param  {Object} _dataObject to convert to a query string
	 * @returns {String} resulting query string
	 */
	function _createQueryString(_dataObject) {
		return (
			"?" +
			Object.entries(_dataObject)
				.map((entry) => {
					return encodeURIComponent(entry[0]) + "=" + encodeURIComponent(entry[1]);
				})
				.join("&")
		);
	}

	/** Extracts response payload depending on contentType
	 * @param {Response} _response to extract payload from
	 * @returns {Object|String} response payload
	 */
	async function _extractResponseData(_response) {
		switch (_request.contentType) {
			case "application/json":
				return await _response.json();
			case "application/text":
				return await _response.text();
			default:
				throw new Error(`Unknown contentType "${response.contentType}"`);
		}
	}
}

/** bla
 * @export
 * @param {String} _topic bla
 * @param {bla} _credentials bla
 * @returns {bla} bla
 */
export async function requestSourceData2(_topic, _credentials) {
	const response = await genericRequest3(requestEnum.SOURCEDATA, {topic: _topic, userName: _credentials});
	return response;
}

/** bla
 * @export
 * @param {String} _topic bla
 * @param {bla} _credentials bla
 * @returns {bla} bla
 */
export async function requestConnectorSourceData() {
	const response = await genericRequest3(requestEnum.CONNECTORDATA, {});
	return response;
}
